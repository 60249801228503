/** Sticky header **/
var pageWindow = $(window);

function checkSticky(pageScroll) {
  if (pageScroll.scrollTop() > 140) {
    $("#header").addClass("turn-sticky");
  } else {
    $("#header").removeClass("turn-sticky");
  }
}

function stickyheader() {
  $(window).scroll(function () {
    checkSticky(pageWindow);
  });
}

function stickyCompensation() {
  var headerHeight = $("#header").outerHeight();

  if (!$("body").hasClass("front")) {
    $("#wrapper").css("padding-top", headerHeight);
  }
}

/** Open menu **/
function openMenu() {
  $(".adtm_toggle_menu_button").on("click", function () {
    setTimeout(function () {
      if ($(".adtm_touch").hasClass("adtm_menu_toggle_open")) {
        $("body").addClass("open-menu");
      } else {
        $("body").removeClass("open-menu");
      }
    }, 100);
  });
}

/** Close Bandeau **/
function closePromo() {
  $(".block-close").on("click", function () {
    $("#ps_banner_ajax").addClass("close-promo");
  });
}

/** Slider small text **/
const sliderMobile = {
  dots: true,
  arrows: true,
  infinite: false,
  speed: 300,
  slidesToShow: 1,
  slidesToScroll: 1,
  mobileFirst: true,
  responsive: [
    {
      breakpoint: 577,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 992,
      settings: "unslick",
    },
  ],
};

function sliderAssurance() {
  const assuranceSlider = $(".block-small--image-text-slider .elementor-row");
  assuranceSlider.not(".slick-initialized").slick(sliderMobile);
}

/** Slider product **/
const sliderProductMobile = {
  dots: true,
  arrows: false,
  infinite: false,
  speed: 300,
  slidesToShow: 1,
  slidesToScroll: 1,
  mobileFirst: true,
  responsive: [
    {
      breakpoint: 577,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 992,
      settings: "unslick",
    },
  ],
};

function sliderProduct() {
  const productSlider = $(".listing-mob-slider.listing");
  productSlider.not(".slick-initialized").slick(sliderProductMobile);
}

/** Slider Instagram **/
const sliderInstagram = {
  dots: false,
  arrows: false,
  infinite: true,
  speed: 300,
  slidesToShow: 1,
  slidesToScroll: 2,
  mobileFirst: true,
  rows: 0,
  responsive: [
    {
      breakpoint: 577,
      settings: {
        slidesToShow: 4,
      },
    },
    {
      breakpoint: 992,
      settings: {
        arrows: true,
        slidesToShow: 4,
      },
    },

    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 4,
        arrows: true,
      },
    },
  ],
};

function InstagramSlider() {
  $(".instagram_list_img").each(function () {
    const instaSlider = $(this);
    instaSlider.on("init", function (event, slick) {
      $(this).addClass("overflow-visible");
    });
    instaSlider.not(".slick-initialized").slick(sliderInstagram);
  });
}

/** Slider Instagram **/
const sliderInstagramRecette = {
  dots: false,
  arrows: false,
  infinite: true,
  speed: 300,
  slidesToShow: 1,
  slidesToScroll: 2,
  mobileFirst: true,
  cssEase: "linear",
  centerMode: true,
  centerPadding: "0",
  rows: 0,
  responsive: [
    {
      breakpoint: 577,
      settings: {
        slidesToShow: 4,
      },
    },
    {
      breakpoint: 992,
      settings: {
        arrows: true,
        slidesToShow: 4,
      },
    },
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 2,
        arrows: true,
      },
    },
  ],
};

function InstagramSliderRecette() {
  $(".instagram_list_recette").each(function () {
    const instaSliderRecette = $(this);
    instaSliderRecette.on("init", function (event, slick) {
      $(this).addClass("overflow-visible");
    });
    instaSliderRecette.not(".slick-initialized").slick(sliderInstagramRecette);
  });
}

// function InstagramSlider() {
//   const instaSlider = $(".instagram_list_img");
//   instaSlider.on("init", function (event, slick) {
//     $(this).addClass("overflow-visible");
//   });
//   instaSlider.not(".slick-initialized").slick(sliderInstagram);
// }

/** Slider TextImages **/
const sliderTextImages = {
  dots: true,
  arrows: false,
  infinite: true,
  speed: 300,
  slidesToShow: 1,
  slidesToScroll: 1,
  centerMode: true,
  centerPadding: "0",
  mobileFirst: true,
  rows: 0,
  responsive: [
    {
      breakpoint: 577,
      settings: {
        slidesToShow: 3,
      },
    },
  ],
};

function TextImagesSlider() {
  const ImagesTextSlider = $(".block-text--three-images .elementor-row");
  ImagesTextSlider.on("init", function (event, slick) {
    $(this).addClass("overflow-visible");
  });
  ImagesTextSlider.not(".slick-initialized").slick(sliderTextImages);
}

/** Year Slider **/
const sliderYear = {
  dots: false,
  arrows: false,
  infinite: true,
  speed: 300,
  slidesToShow: 1,
  slidesToScroll: 1,
  centerMode: true,
  mobileFirst: true,
  autoplay: false,
  rows: 0,
  centerPadding: "0",
  responsive: [
    {
      breakpoint: 577,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 4,
      },
    },

    {
      breakpoint: 992,
      settings: {
        slidesToShow: 5,
        arrows: true,
      },
    },
  ],
};

function YearSlider() {
  const ImagesYearSlider = $(".year-slider .carousel-inner");
  ImagesYearSlider.on("init", function (event, slick) {
    $(this).addClass("overflow-visible");
  });
  ImagesYearSlider.not(".slick-initialized").slick(sliderYear);
}

/** Slider small text **/
const sliderCoffret = {
  dots: false,
  arrows: false,
  infinite: false,
  speed: 300,
  slidesToShow: 2,
  slidesToScroll: 1,
  mobileFirst: true,
  rows: 0,
  responsive: [
    {
      breakpoint: 577,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 4,
      },
    },
  ],
};

// function coffretSlider() {
//   const coffretsSlider = $(
//     ".block-tabs .block-coffret--slider .ce-product-grid"
//   );
//   coffretsSlider.on("init", function (event, slick) {
//     $(this).addClass("overflow-visible");
//   });
//   coffretsSlider.not(".slick-initialized").slick(sliderCoffret);
// }

function coffretSlider() {
  $(".block-tabs .block-coffret--slider .ce-product-grid").each(function () {
    const coffretSlider = $(this);
    coffretSlider.on("init", function (event, slick) {
      $(this).addClass("overflow-visible");
    });
    if (!coffretSlider.hasClass("slick-initialized")) {
      coffretSlider.slick(sliderCoffret);
    }
  });
}

/** Reinitialise slick on chnage tabs **/
function tabsChange() {
  $(".block-tabs .elementor-tab-title").on("click", function () {
    $(".block-tabs .block-coffret--slider .ce-product-grid").each(function () {
      $(this).slick("unslick"); // Remove the slick
      $(this).slick(sliderCoffret); // Re-initialize the slick
    });
  });
}

/** Slider small text **/
const sliderFeaturedProducts = {
  dots: false,
  arrows: false,
  infinite: false,
  speed: 300,
  slidesToShow: 2,
  slidesToScroll: 1,
  mobileFirst: true,
  responsive: [
    {
      breakpoint: 577,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 4,
        arrows: true,
        dots: true,
      },
    },
  ],
};

function FeaturedProductsSlider() {
  const productsSlider = $(".block-featured--products .products");
  productsSlider.on("init", function (event, slick) {
    $(this).addClass("overflow-visible");
  });
  productsSlider.not(".slick-initialized").slick(sliderFeaturedProducts);
}

/** Slider category **/
const sliderProductsCategory = {
  dots: false,
  arrows: false,
  infinite: false,
  speed: 300,
  slidesToShow: 2,
  slidesToScroll: 1,
  mobileFirst: true,
  prevArrow: $(".block-category--products .slick-nav-arrow--prev"),
  nextArrow: $(".block-category--products .slick-nav-arrow--next"),
  appendDots: $(".slick-dots--category"),
  responsive: [
    {
      breakpoint: 577,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 4,
        arrows: true,
        dots: true,
      },
    },
  ],
};

function ProductsSliderCategory() {
  const productsSlider = $(".block-category--products .products");
  productsSlider.on("init", function (event, slick) {
    $(this).addClass("overflow-visible");
  });
  productsSlider.not(".slick-initialized").slick(sliderProductsCategory);
}

/** Slider Viewed Products **/
const sliderProductsViewed = {
  dots: false,
  arrows: false,
  infinite: false,
  speed: 300,
  slidesToShow: 2,
  slidesToScroll: 1,
  mobileFirst: true,
  prevArrow: $(".block-viewed--products .slick-nav-arrow--prev"),
  nextArrow: $(".block-viewed--products .slick-nav-arrow--next"),
  appendDots: $(".block-viewed--products .slick-dots--viewed"),
  responsive: [
    {
      breakpoint: 577,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 4,
        arrows: true,
        dots: true,
      },
    },
  ],
};

function ProductsSliderViewed() {
  const productsSlider = $(".block-viewed--products .products");
  productsSlider.on("init", function (event, slick) {
    $(this).addClass("overflow-visible");
  });
  productsSlider.not(".slick-initialized").slick(sliderProductsViewed);
}

/** Slider Promo **/
const sliderPromo = {
  dots: false,
  arrows: false,
  infinite: true,
  speed: 400,
  autoplay: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  mobileFirst: true,
  rows: 0,
};

function bandeauPromoSlider() {
  const promoSlider = $("#ps_topbanner_wrapper");
  promoSlider.not(".slick-initialized").slick(sliderPromo);
}

/** Slider Degustation **/
const sliderDegustation = {
  dots: true,
  arrows: false,
  infinite: false,
  speed: 300,
  slidesToShow: 1,
  slidesToScroll: 1,
  mobileFirst: true,
  rows: 0,
  responsive: [
    {
      breakpoint: 577,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 992,
      settings: "unslick",
    },
  ],
};

// function DegustationSlider() {
//   const designSlider = $(
//     ".block-degustation-design > .elementor-container > .elementor-row"
//   );
//   designSlider.on("init", function (event, slick) {
//     $(this).addClass("overflow-visible");
//   });
//   designSlider.not(".slick-initialized").slick(sliderDegustation);
// }

function DegustationSlider() {
  const designSliders = $(
    ".block-degustation-design > .elementor-container > .elementor-row"
  );

  designSliders.each(function () {
    const designSlider = $(this);
    designSlider.on("init", function (event, slick) {
      designSlider.addClass("overflow-visible");
    });
    if (!designSlider.hasClass("slick-initialized")) {
      designSlider.slick(sliderDegustation);
    }
  });
}

/** Slider Small Product tablette **/
const sliderSmallProductTablet = {
  dots: true,
  arrows: false,
  infinite: false,
  speed: 300,
  slidesToShow: 1,
  slidesToScroll: 1,
  mobileFirst: true,
  rows: 0,
  responsive: [
    {
      breakpoint: 577,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 992,
      settings: "unslick",
    },
  ],
};

function SmallProductTabletSlider() {
  const smallSlider = $(".block-small-products > div > div");
  smallSlider.on("init", function (event, slick) {
    $(this).addClass("overflow-visible");
  });
  smallSlider.not(".slick-initialized").slick(sliderSmallProductTablet);
}

/** Slim Select **/
function slimSelect() {
  const selects = document.querySelectorAll(".form-select");
  selects.forEach((selectElement) => {
    new SlimSelect({
      select: selectElement,
      settings: {
        showSearch: false,
      },
    });
  });
}

/** Slim Select **/
function ControlSelect() {
  const selects = document.querySelectorAll(".form-control-select");
  selects.forEach((selectElement) => {
    new SlimSelect({
      select: selectElement,
      settings: {
        showSearch: false,
      },
    });
  });
}

/** Search filter **/
function FilterSearch() {
  new SlimSelect({
    select: ".search-filter .elementor-field",
    settings: {
      showSearch: false,
    },
  });
}

/** catalogue filter **/
function FilterProducts() {
  new SlimSelect({
    select: ".block-filter .elementor-field",
    settings: {
      showSearch: false,
    },
  });
}

/** Block le ganaches accordeon **/
function tabsAccordeonGanache() {
  if ($(window).width() < 768) {
    $(".block-composition--ganaches .elementor-tab-mobile-title").removeClass(
      "elementor-active"
    );
    $(".block-composition--ganaches .elementor-tab-content").hide();
    $(".block-composition--ganaches .elementor-tab-mobile-title")
      .off("click")
      .on("click", function () {
        $(this).toggleClass("elementor-active");
        $(this).next(".elementor-tab-content").toggle();
      });
  }
}

function listMarquee() {
  $(".marquee-wrapper").marquee({
    speed: 15,
    spaceBetween: 55,
  });
}

/** Slider faq ques **/
const sliderFaqQues = {
  dots: false,
  arrows: false,
  infinite: false,
  autoplay: false,
  variableWidth: true,
  speed: 300,
  slidesToShow: 1,
  slidesToScroll: 1,
  mobileFirst: true,
};

function FaqQuesSlider() {
  if ($(window).width() < 768) {
    const faqsSlider = $(".block-faq--questions .elementor-widget-wrap");
    faqsSlider.on("init", function (event, slick) {
      $(this).addClass("overflow-visible");
    });
    faqsSlider.not(".slick-initialized").slick(sliderFaqQues);
  }
}

/** Slider faq ques **/
const sliderLatestBlog = {
  dots: false,
  arrows: false,
  infinite: false,
  autoplay: false,
  speed: 300,
  slidesToShow: 2,
  slidesToScroll: 1,
  rows: 0,
  prevArrow: $(".slick-nav-arrow--prev"),
  nextArrow: $(".slick-nav-arrow--next"),
  appendDots: $(".slick-dots--custom"),
  mobileFirst: true,
  responsive: [
    {
      breakpoint: 576,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 4,
        arrows: true,
        dots: true,
      },
    },
  ],
};

function LatestBlogSlider() {
  const BlogSlider = $(".latest-blog-slider");
  BlogSlider.on("init", function (event, slick) {
    $(this).addClass("overflow-visible");
  });
  BlogSlider.not(".slick-initialized").slick(sliderLatestBlog);
}

/** Slider faq ques **/
const MobileMediaTextSlider = {
  dots: true,
  arrows: false,
  infinite: false,
  autoplay: false,
  speed: 300,
  slidesToShow: 1,
  slidesToScroll: 1,
  rows: 0,
  mobileFirst: true,
  responsive: [
    {
      breakpoint: 768,
      settings: "unslick",
    },
  ],
};

function SliderMediaTextMobile() {
  const MediaSlider = $(
    ".block-media--text-becomes-slider-mobile .elementor-row"
  );
  MediaSlider.on("init", function (event, slick) {
    $(this).addClass("overflow-visible");
  });
  MediaSlider.not(".slick-initialized").slick(MobileMediaTextSlider);
}

/** Faq menu sticky **/
function FaqMenuSticky() {
  if ($(window).width() < 1200) {
    $(".block-faq--ques.sticky").stick_in_parent();
  }
}

/** Slider Slider **/
const sliderProductsImages = {
  dots: false,
  arrows: true,
  infinite: true,
  variableWidth: true,
  speed: 300,
  slidesToShow: 4,
  slidesToScroll: 1,
  mobileFirst: true,
  rows: 0,
};

function ImagesProductsSlider() {
  if ($(window).width() > 1199) {
    $(".block-products-slider .product-images--slider").each(function () {
      if (!$(this).hasClass("slick-initialized")) {
        $(this).slick(sliderProductsImages);
      }
    });
  }
}

function MobileProductSlider() {
  $(".product-images--slider li").each(function () {
    $(this).on("click", function () {
      $(".product-images--slider li").removeClass("active");
      $(this).addClass("active");
    });
  });
}

// function ImagesProductsSlider() {
//   if ($(window).width() > 1199) {
//     const productsSlider = $(".product-images--slider");
//     // productsSlider.on("init", function (event, slick) {
//     //   $(this).addClass("overflow-visible");
//     // });
//     productsSlider.not(".slick-initialized").slick(sliderProductsImages);
//   }
// }

// function MobileProductSlider() {
//   $(".product-images--slider li").on("click", function () {
//     $(".product-images--slider li").removeClass("active");
//     $(this).addClass("active");
//   });
// }

/** Slider Product Exclusifs **/
// const sliderProductsImagesExclusifs = {
//   dots: true,
//   arrows: false,
//   infinite: false,
//   variableWidth: false,
//   speed: 300,
//   slidesToShow: 4,
//   slidesToScroll: 1,
//   mobileFirst: true,
//   rows: 0,
//   responsive: [
//     {
//       breakpoint: 1199,
//       settings: {
//         slidesToShow: 1,
//         arrows: true,
//         dots: true,
//       },
//     },
//   ],
// };

// function ExclusifsImagesProductsSlider() {
//   if ($(window).width() > 1199) {
//     $(".block-product-exclusifs .product-images--slider").each(function () {
//       if (!$(this).hasClass("slick-initialized")) {
//         $(this).slick(sliderProductsImagesExclusifs);
//       }
//     });
//   }
// }

function ClosePanier() {
  $(".cntd-shopping").on("click", function () {
    $(".elementor-cart__container").removeClass("elementor-cart--shown");
  });
}

function OpenCart() {
  $(
    ".elementor-cart__toggle, .product__buttons .elementor-button, .add-con-us button"
  ).on("click", function () {
    $("body").addClass("open-cart");
  });
}

function CloseCart() {
  $(".elementor-cart__close-button").on("click", function () {
    $("body").removeClass("open-cart");
  });
}

function CloseCartOutside() {
  $(document).mouseup(function (e) {
    var container = $(".elementor-cart__main");

    // if the target of the click isn't the container nor a descendant of the container
    if (!container.is(e.target) && container.has(e.target).length === 0) {
      $("body").removeClass("open-cart");
    }
  });
}

function CloseNotifs() {
  $(".alert-success__close, .alert-danger__close").on("click", function () {
    $(".block-notifs").addClass("close-notifs");
  });
}

function SecretProAccordeon() {
  $(".block-atelier__chevron").on("click", function () {
    var $accordion = $(this)
      .closest(".block-atelier__inner")
      .find(".block-atelier__accordeon");
    if ($accordion.hasClass("open")) {
      $accordion.removeClass("open");
      $(this).removeClass("active");
    } else {
      $(".block-atelier__accordeon.open").removeClass("open");
      $(".block-atelier__chevron").removeClass("active");
      $accordion.addClass("open");
      $(this).addClass("active");
    }
  });
}

function CloseSecretProAccordeonOutside() {
  $(document).mouseup(function (e) {
    var container = $(".block-atelier__wrap");

    // if the target of the click isn't the container nor a descendant of the container
    if (!container.is(e.target) && container.has(e.target).length === 0) {
      $(".block-atelier__accordeon").removeClass("open");
      $(".block-atelier__chevron").removeClass("active");
    }
  });
}

function OpenRecetteCategory() {
  $(".block-recette-list .ets-category-blog-parent").on("click", function () {
    var $parentLi = $(this).closest("li");

    if ($parentLi.hasClass("open")) {
      $parentLi.removeClass("open");
    } else {
      $(".block-recette-list li").removeClass("open");
      $parentLi.addClass("open");
    }
  });
}

function CloseRecetteCategory() {
  $(document).mouseup(function (e) {
    var container = $(".block-recette-list");

    // if the target of the click isn't the container nor a descendant of the container
    if (!container.is(e.target) && container.has(e.target).length === 0) {
      $(".block-recette-list li").removeClass("open");
    }
  });
}

/** Slider recent post **/
const sliderRecentPost = {
  dots: true,
  arrows: false,
  infinite: false,
  speed: 300,
  slidesToShow: 1,
  slidesToScroll: 1,
  mobileFirst: true,
  rows: 0,
};

function RecentPostSlider() {
  const PostSlider = $(".slider-recent-post");
  PostSlider.on("init", function (event, slick) {});
  PostSlider.not(".slick-initialized").slick(sliderRecentPost);
}

function CategoryAccordeon() {
  $(".block-category-btn").on("click", function () {
    $(".block_content").toggleClass("open");
  });
}

/** Slider Recipe Product **/
const SliderProductRecipe = {
  dots: false,
  arrows: false,
  infinite: false,
  speed: 300,
  slidesToShow: 2,
  slidesToScroll: 1,
  mobileFirst: true,
  rows: 0,
  responsive: [
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 3,
      },
    },
  ],
};

function RecipeProductSlider() {
  const recipeSlider = $(".produit-recette .ce-product-grid");
  recipeSlider.on("init", function (event, slick) {
    $(this).addClass("overflow-visible");
  });
  recipeSlider.not(".slick-initialized").slick(SliderProductRecipe);
}

/** Slider Recipe Product **/
const SliderListRecipe = {
  dots: true,
  arrows: false,
  infinite: false,
  speed: 300,
  slidesToShow: 1,
  slidesToScroll: 1,
  mobileFirst: true,
  rows: 0,
  responsive: [
    {
      breakpoint: 577,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 4,
        arrows: true,
        dots: false,
      },
    },
  ],
};

function RecipeListSlider() {
  const recipeListSlider = $(".list-recette--slider");
  recipeListSlider.on("init", function (event, slick) {});
  recipeListSlider.not(".slick-initialized").slick(SliderListRecipe);
}

function CustomScrollExclusifs() {
  $(
    ".form-group .ndk_accessory_list, .modal-products-desc--with-scroll .modal-body"
  ).mCustomScrollbar({
    theme: "dark-3",
    scrollButtons: { enable: false },
  });
}

function WrapPagination() {
  $(".block-present--products-slider-with-top-pagination").each(function () {
    $(this)
      .find(
        ".elementor-swiper-button-prev, .elementor-swiper-button-next, .swiper-pagination-bullets"
      )
      .wrapAll('<div class="swiper-navigation-wrapper"></div>');
  });
}

function ProductRecipeScroll() {
  $(".produit-recette .slick-list").mCustomScrollbar({
    theme: "dark-3",
    axis: "x",
    scrollButtons: { enable: false },
  });
}

jQuery(document).ready(function () {
  /** Icons feather **/
  feather.replace();

  /** Product Images Slider Active pagination **/
  MobileProductSlider();

  /** Product Images Slider **/
  ImagesProductsSlider();

  /** Faq ques slider **/
  FaqQuesSlider();

  /** Assurance Slider **/
  sliderAssurance();

  /** Slider Instagram **/
  InstagramSlider();

  /** Slider Coffret **/
  coffretSlider();

  /** Close promo **/
  closePromo();

  /** FeaturedProductsSlider **/
  FeaturedProductsSlider();

  /** Slider product category **/
  ProductsSliderCategory();

  /** Slider Viewed prpducts **/
  ProductsSliderViewed();

  /** PromoSlider **/
  bandeauPromoSlider();

  /** DegustationSlider **/

  /** Slim Select **/
  slimSelect();

  /** Slim Select default **/
  ControlSelect();

  /** Reinitialise slick on change tabs **/
  tabsChange();

  /** Block le ganaches accordeon **/
  tabsAccordeonGanache();
  $(window).resize(tabsAccordeonGanache);

  /* Product slider */
  sliderProduct();

  /* SmallProductTablet Slider */
  SmallProductTabletSlider();

  /* Open Menu */
  openMenu();

  /* Sticky faq menu */
  FaqMenuSticky();

  /* Latest blog slider */
  LatestBlogSlider();

  /* Slider Media Text */
  SliderMediaTextMobile();

  /* Open Cart */
  OpenCart();

  /* Close Cart */
  CloseCart();

  /* Close Cart Outside */
  CloseCartOutside();

  /* Close notifs */
  CloseNotifs();

  /* Text Images slider Page Metier */
  TextImagesSlider();

  /* Year Slider notre histoire */
  YearSlider();

  /* Open recette Category */
  OpenRecetteCategory();

  /** custom Scroll product exclusifs **/
  CustomScrollExclusifs();

  /* Slider product exclusifs */
  //ExclusifsImagesProductsSlider();
  setTimeout(function () {
    ExclusifsImagesProductsSlider();
  }, 1000);
});

$(window).on("load", function () {
  /** Sticky header **/
  checkSticky(pageWindow);
  stickyheader();
  stickyCompensation();

  /** Degustation Slider **/
  DegustationSlider();

  /** List Marquee **/
  listMarquee();

  /** Filter Search **/
  FilterSearch();

  /* Catalogue filter products */
  FilterProducts();

  /* Close cart when empty */
  ClosePanier();

  /* Secret Pro Open content */
  SecretProAccordeon();

  /* Close Secret Pro Open content */
  CloseSecretProAccordeonOutside();

  /* Open Recette Category */
  OpenRecetteCategory();

  /* Close Recette Category */
  CloseRecetteCategory();

  /* recent Post Slider */
  RecentPostSlider();

  /* Responsive accordeon category */
  CategoryAccordeon();

  /* Slider produits recette */
  RecipeProductSlider();

  /* Slider recent recette */
  RecipeListSlider();

  /** custom Scroll product exclusifs **/
  CustomScrollExclusifs();

  /** Wrap pagination & bullets **/
  WrapPagination();

  /* Slider instagram recette */
  InstagramSliderRecette();

  /* Slider product exclusifs */
  setTimeout(function () {
    ExclusifsImagesProductsSlider();
  }, 1000);

  /* Product Slider recette */
  ProductRecipeScroll();
});

$(window).on("resize", function () {
  stickyCompensation();
});
